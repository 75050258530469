import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Спектр послуг гольф-відпочинку Campa Club
			</title>
			<meta name={"description"} content={"Відкрийте для себе першокласні послуги гольф-клубу Campa Club, де кожен удар - це симфонія спокою"} />
			<meta property={"og:title"} content={"Наші послуги | Спектр послуг гольф-відпочинку Campa Club"} />
			<meta property={"og:description"} content={"Відкрийте для себе першокласні послуги гольф-клубу Campa Club, де кожен удар - це симфонія спокою"} />
			<meta property={"og:image"} content={"https://vistacorefill.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vistacorefill.com/img/5950503.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vistacorefill.com/img/5950503.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vistacorefill.com/img/5950503.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vistacorefill.com/img/5950503.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vistacorefill.com/img/5950503.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vistacorefill.com/img/5950503.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-2">
			<Box border-radius="24px" margin="0px 0px 40px 0px">
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline1"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Наші послуги
				</Text>
				<Text margin="20px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
				У Campa Club ми пропонуємо цілий ряд послуг, спрямованих на покращення вашого досвіду гри в гольф і забезпечення того, щоб кожна мить на полі була незабутньою.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="35px 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image src="https://vistacorefill.com/img/4.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text
						color="--greyD3"
						md-text-align="center"
						margin="5px 0px 15px 0px"
						font="--headline3"
						width="100%"
						lg-width="100%"
					>
						Найнеобхідніше для гри в гольф
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					- Доступ до чемпіонського поля: Стартуйте на нашому ретельно доглянутому чемпіонському полі, розробленому, щоб кинути виклик і надихнути гравців у гольф усіх рівнів.
						<br />
						<br />
						- Тренувальні майданчики: Відточуйте свої навички на наших найсучасніших тренувальних майданчиках, включаючи автодроми, майданчики для гри в гольф та зони для чипінгу.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image src="https://vistacorefill.com/img/5.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text
						color="--greyD3"
						md-text-align="center"
						margin="5px 0px 15px 0px"
						font="--headline3"
						width="100%"
						lg-width="100%"
					>
						Клубні зручності
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					- Бездоганна кухня: Побалуйте себе вишуканими стравами в нашому клубному ресторані, де на вас чекають кулінарні шедеври, щоб задовольнити ваш апетит після захоплюючої гри в гольф.
						<br />
						<br />
						- Магазин для професіоналів: Відвідайте наш професійний магазин, де ви знайдете найсучасніше спорядження для гольфу, одяг та аксесуари, які допоможуть вам покращити свою гру.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image src="https://vistacorefill.com/img/6.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text
						color="--greyD3"
						md-text-align="center"
						margin="5px 0px 15px 0px"
						font="--headline3"
						width="100%"
						lg-width="100%"
					>
						Події та турніри
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					- Заходи з гольфу: Беріть участь у наших регулярних заходах і турнірах з гольфу, де ви зможете продемонструвати свої навички та поспілкуватися з іншими ентузіастами гольфу.
						<br />
						<br />
						- Приватні заходи: Проведіть свій наступний корпоративний захід, весілля або урочисту подію в нашому мальовничому відпочинковому комплексі з індивідуальними пакетами послуг, пристосованими до ваших потреб.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://vistacorefill.com/img/7.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Варіанти членства
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					- Повне членство: Насолоджуйтесь необмеженим доступом до наших об'єктів та ексклюзивними привілеями лише для членів клубу, включаючи пріоритетний час для гольфу та спеціальні знижки.
					<br/><br/>
					- Перепустки для відвідувачів: Скористайтеся нашими гнучкими гостьовими перепустками, які ідеально підходять для гравців у гольф від випадку до випадку або для тих, хто бажає випробувати Campa Club на тимчасовій основі.

				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});